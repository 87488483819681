import React, { Suspense, lazy } from "react";
import { Switch, Redirect, Router } from "react-router-dom";

const RouteWrapper = lazy(() => import("./../../wrappers/route_wrapper"));
const NanoWrapper = lazy(() => import("./../../wrappers/nano_wrapper"));
const NewHome = lazy(() => import("@containers/newHome"));

import { history } from "@modules/";
import LoadingLanding from "./loading_landing";
const Answer = lazy(() => import("@containers/faq/answer"));
const About = lazy(() => import("@containers/static_pages/about"));
const FAQ = lazy(() => import("@containers/faq"));

const Results = lazy(() => import("@containers/faq/list"));
const Contact = lazy(() => import("@containers/static_pages/contact_us"));
const DoctoLayoutStyled = lazy(() => import("@layouts/doctoLayoutStyled"));

const BrowseHospitalNew = lazy(
  () => import("@containers/static_pages/browse_hospital_new/index"),
);
const ExpertOpinion = lazy(
  () => import("@containers/static_pages/expert_opinion/index"),
);
const ReferAPatient = lazy(
  () => import("@containers/static_pages/refer_a_patient"),
);
const ExecutiveTeam = lazy(
  () => import("@containers/static_pages/executive_team"),
);
const PatientInfo = lazy(() => import("@containers/static_pages/patient_info"));
const PrivacyPolicy = lazy(
  () => import("@containers/static_pages/privacy_policy"),
);
const Press = lazy(() => import("@containers/static_pages/press"));
const ConsultDrInfo = lazy(() => import("@containers/static_pages/consult_dr"));
const Pricing = lazy(() => import("@containers/static_pages/pricing"));
const Adhd = lazy(() => import("@containers/static_pages/adhd"));
const Workspace = lazy(() => import("@containers/static_pages/workspace"));
const MedicalCertificate = lazy(
  () => import("@containers/static_pages/medical_certificate"),
);
const SickKids = lazy(() => import("@containers/static_pages/sick_kids"));

const SpecialtyCms = lazy(() => import("@containers/specialties_cms"));
const Business = lazy(() => import("@containers/static_pages/business"));

const NotFound = lazy(() => import("./not_found"));

const BlogMain = lazy(() => import("@containers/blog"));
const BlogMainFiltered = lazy(() => import("@containers/blog/blog_filtered"));
const BlogPost = lazy(() => import("@containers/blog/blog_post"));

const AudienceBusiness = lazy(() => import("@containers/audience-business"));
const Concussion = lazy(() => import("@containers/concussion"));
const TravelInsurance = lazy(() => import("@containers/travel_insurance"));

const DoctoRoutes = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<LoadingLanding />}>
        <Switch>
          <RouteWrapper
            layout={DoctoLayoutStyled}
            component={NewHome}
            exact
            path="/"
          />
          <NanoWrapper exact name="nano" path="/t/:nano" />
          <Redirect path="/wickham" to="/consult-dr-now" />
          <Redirect from="/online-doctor" to="/consult-dr-now" />
          <Redirect
            from="/signup/gold-coast-private-hospital"
            to="/consult-dr-now"
          />
          <Redirect from="/gold-coast-private-hospital" to="/online-doctor" />
          <Redirect
            from="/login/gold-coast-private-hospital"
            to="/online-doctor"
          />
          <Redirect path="/script" to="/online-doctor" />
          <Redirect path="/wickham" to="/online-doctor" />
          <Redirect path="/karratha777" to="/online-doctor" />

          {/**blog */}
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog"}
            component={BlogMain}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/workers-comp"}
            component={AudienceBusiness}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/concussion"}
            component={Concussion}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/travel-insurance"}
            component={TravelInsurance}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog/search/"}
            component={BlogMainFiltered}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog/:slug"}
            component={BlogPost}
          />

          {/* EndRedirect companies */}

          <RouteWrapper
            path="/not-found"
            layout={DoctoLayoutStyled}
            exact
            component={NotFound}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/contact"}
            component={Contact}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/consult-dr-now"}
            component={ConsultDrInfo}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/pricing"}
            component={Pricing}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/adhd"}
            component={Adhd}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/workspace"}
            component={Workspace}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/medical-certificate"}
            component={MedicalCertificate}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/paediatrics"}
            component={SickKids}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/kids"}
            component={SickKids}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/about"}
            component={About}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path="/faq/results"
            component={Results}
          />
          <RouteWrapper
            exact
            layout={DoctoLayoutStyled}
            path="/faq/category/:category"
            component={Results}
          />
          <RouteWrapper
            exact
            layout={DoctoLayoutStyled}
            path={"/faq/:slug"}
            component={Answer}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/faq"}
            component={FAQ}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/business"}
            component={Business}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/refer-patient"}
            component={ReferAPatient}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/executive-team"}
            component={ExecutiveTeam}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/privacy-policy"}
            component={PrivacyPolicy}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/patient-info"}
            component={PatientInfo}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/browse-hospital"}
            component={BrowseHospitalNew}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/expert-opinion"}
            component={ExpertOpinion}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/press"}
            component={Press}
          />
          <RouteWrapper
            path="/:slug"
            layout={DoctoLayoutStyled}
            exact
            component={SpecialtyCms}
          />
          <RouteWrapper layout={DoctoLayoutStyled} exact component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default DoctoRoutes;
